import React from 'react'
import Seo from '../../../components/Seo'
import BigTitle from '../../../components/BigTitle'
import DefaultSection from '../../../components/DefaultSection'
import Article from '../../../components/Article'
import TeaserSection from '../../../components/TeaserSection'
import TeaserHeader from '../../../components/TeaserHeader'
import IndividualContactSection from '../../../components/IndividualContactSection'


const Page = () => {

  return (
    <React.Fragment>
      <Seo title="Werkstudent im Bereich Softwareentwicklung (m/w/d)"
           description="Du bist auf der Suche nach einem spannenden Job in einem innovativen IT-Unternehmen? Bewirb Dich jetzt für einen Platz als Werkstudent und sammle wertvolle Erfahrungen, während du gleichzeitig Deine Finanzen etwas aufbesserst!"/>
      <BigTitle title="Stellenangebot" imageRelativePath={'Titelbild_Karriere.jpg'} className="mask-black"
                textInverted={true}
                menuInverted={true} logoInverted={true}>Du bist auf der Suche nach einem
        spannenden Werkstudenten-Job in einem innovativen IT-Unternehmen? Dann bist Du hier genau richtig.</BigTitle>

      <DefaultSection>
        <Article>
          <h1>Werkstudent im Bereich Softwareentwicklung (m/w/d)</h1>
          <h2>Deine Aufgabe</h2>
          <p>Als Werkstudent im Bereich Softwareentwicklung (m/w/d) arbeitest Du an unseren internen Projekten mit
            und lernst dabei neue Technologien kennen und wie kundenorientierte Softwarelösungen nach allen Regeln der
            Softwaretechnik erstellt werden.</p>
          <p>Idealerweise steigst Du bei uns in den Semesterferien ein, so dass wir Dich innerhalb von zwei Wochen mit
            je 40 h perfekt in Dein Projekt einarbeiten können. Anschließend kannst Du bei uns 8 bis 20 h pro Woche arbeiten, in
            der vorlesungsfreien Zeit gerne bis zu 40 h pro Woche. </p>
          <p>Wir sind an einer langfristigen Zusammenarbeit interessiert. Daher wäre es ideal, wenn Du uns für mind. 1
            Jahr zur Verfügung stehen kannst.
          </p>
          <h2>Dein Profil</h2>
          <p>
            <ul>
              <li>Im Studium der Informatik oder eines verwandten Studiengangs hast Du bereits umfassende theoretische
                Kenntnisse zu Deinem Fachgebiet erlangt, die Du nun praktisch anwenden möchtest.
              </li>
              <li>Du hegst eine starke Affinität zur Softwareentwicklung und hast bereits erste Erfahrungen im
                Programmieren mit Java-Technologien sammeln können.
              </li>
              <li>Spring, Spring Boot, Angular und ReactJS sind für Dich keine Fremdwörter.</li>
              <li>Du bist kommunikativ, organisiert und arbeitest selbstständig.</li>
              <li>Neben sehr guten Deutschkenntnissen (mind. C1 Niveau) und guten Englischkenntnissen besitzt Du soziale
                Kompetenz und Begeisterungsfähigkeit.
              </li>
            </ul>
          </p>
          <h2>Deine Benefits bei BUSCHMAIS</h2>
          <p>
            <ul>
              <li>Wir legen viel Wert auf die Vereinbarkeit von Privat- und Berufsleben und wissen, dass Dein Studium
                natürlich vorgeht.
              </li>
              <li>Du sammelst praktische Erfahrungen im Umgang mit modernen Technologien.</li>
              <li>Nach individueller Absprache ist regelmäßiges Homeoffice kein Problem.</li>
              <li>Uns ist eine direkte Kommunikation enorm wichtig, weswegen die Entscheidungswege so kurz wie nur
                möglich gehalten sind.
              </li>
              <li>Langeweile kommt bei uns nicht auf, denn wir veranstalten Ausflüge und Grillabende und nehmen an
                Sportevents teil.
              </li>
              <li>Mit einem erfolgreichen Abschluss bieten wir Dir Perspektiven bei BUSCHMAIS.</li>
            </ul>
          </p>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-1" anchor="bewerben">
        <TeaserHeader title="Begeistert?">
          <p>
            Das freut uns. Schreibe uns gerne eine E-Mail mit Deinen Vorstellungen und Deinem Lebenslauf. Für Rückfragen
            stehen wir Dir gerne zur Verfügung.
          </p>
          <p>Bitte beachte, dass Bewerbungen über die E-Mail-Dienste
            Outlook und Gmail aus technischen Gründen ggf. nicht bei uns ankommen.</p>
          <p className="mt-5">
            <a className="btn btn-outline-black" href="mailto:jobs@buschmais.com"
               title="Jetzt per Mail bewerben">Jetzt per Mail bewerben</a>
          </p>
        </TeaserHeader>
      </TeaserSection>

      <IndividualContactSection personKey={'christiane'} email={'jobs@buschmais.com'} tel={'+49 351 320923 11'}/>

    </React.Fragment>
  )
}

export default Page
